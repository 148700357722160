* {box-sizing: border-box;margin: 0;padding: 0;}
body {float: left;width: 100%;margin: 0;padding: 0;box-sizing: border-box;position: relative;overflow: hidden;background-color: #f2f2f2 !important;}
.wrapper {float: left;width: 100%;overflow: hidden;}
/* html {scrollbar-color: #f1f1f1 #ccc;scrollbar-width: thin;}
html:hover {
scrollbar-color: #888 #555;
} */
.profilUser h4 {
  font-size: 15px;
  margin: 0px;
}
.profilUser small {
  text-transform: capitalize;
  font-size: 13px;
}
.framescanner_modal {
  float: left;
  width: 100%;
  height: 100dvh;
  position: fixed;
  left: 0;
  top: 0;
  background-color: #fff;
  z-index: 999;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.framescanner_modal .frma_scanner_box {
  float: left;
  width: 315px;
  height: 315px;
  text-align: center;
  margin: 0 0 10px;
}
.try_againa_scanner {
  padding: 7px 21px !important;
  border: 1px solid #333 !important;
  font-size: 14px !important;
  float: none;
  text-align: center !important;
  margin: 0 auto;
  display: table !important;
  border-radius: 30px !important;
  font-weight: 500 !important;
}
.framescanner_modal .frma_scanner_box #scanner, .framescanner_modal .frma_scanner_box #scanner video {
  height: 100% !important;
  width: 100% !important;
  position: relative;
}
.framescanner_modal .frma_scanner_box #scanner .qrscanning_image {
  float: left;
  width: 90%;
  height: 65%;
  position: absolute;
  left: 15px;
  top: 51px;
  z-index: 9;
}
.logoutbtn {
  font-size: 14px !important;
  border: 1px solid #c20000 !important;
  color: #c20000 !important;
  width: 100% !important;
  padding: 8px 10px !important;
  border-radius: 0px !important;
}
.framescanner_modal .close_scanner_btn {
  float: left;
  width: auto;
  font-size: 35px;
  color: red;
  position: absolute;
  right: 15px;
  top: 10px;
  cursor: pointer;
}
.framescanner_modal h4 {
  float: left;
  width: 100%;
  text-align: center;
  margin: 0px 0px 10px;
  font-weight: 800;
}
.qr_scanning_btn {
  padding: 5px 8px;
  border-radius: 0px;
  font-size: 16px;
}
.profilUser {
  float: left;
  width: auto;
  line-height: 16px;
  text-align: left;
}
.permissionmodel h5 {
  font-size: 17px;
  margin: 0px;
}
.permissiontableView tr td:first-child, .permissiontableView tr th:first-child {
  text-align: left;
}
.permissiontableView td, .permissiontableView th {
  text-align: center;
}
.permissionCenter {
  float: left;
  width: 100%;
  text-align: center;
  height: calc(100vh - 99px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.permissionCenter .errorMessageIcon {
  font-size: 19px;
}
.permissiontableView tbody tr th.labeltd {
  font-size: 13px;
  font-weight: 400;
  padding: 10px 10px;
  background-color: #f6f6f6 !important;
  color: #212529;
  position: relative;
}
.searchInfo {
position: absolute;
top: 9px;
left: 7px;
background-color: #fff;
font-size: 13px;
cursor: pointer;
}

/* Targeting WebKit-based browsers */
.Bigform_custom_scroll::-webkit-scrollbar {
width: 4px; /* Width of the scrollbar */
}

.Bigform_custom_scroll::-webkit-scrollbar-track {
background-color: #f1f1f1; /* Track color */
}

.Bigform_custom_scroll::-webkit-scrollbar-thumb {
background-color: #888; /* Thumb color */
border-radius: 6px; /* Rounded corners for the thumb */
}

.Bigform_custom_scroll::-webkit-scrollbar-thumb:hover {
background-color: #555; /* Thumb color on hover */
}

:root {
  --loginColor: #0E0E30;
  --maincolor: #0e39b6;
}

/* sidebar css */
.sidebarWrapper {float: left;width: 301px;display: flex;height: calc(100vh - 58px);border-right: 3px solid #fff;position: relative;}
.sidebarWrapper::after {content: "";float: left;width: 1px;height: 100%;background-color: #ddd;position: absolute;right: -3px;top: 0;}
.sidebarMain {float: left;width: 50px;background-color: #0E0E30;}
.sidebarMain .flex-column {overflow: hidden;}
.sidebarMain .nav-link {
padding: 13px 10px;
float: left;
width: 100%;
text-align: center;
cursor: pointer;
position: relative;
display: flex;
align-items: center;
justify-content: center;
font-size: 15px;
color: #fff;
}
.sidebarMain .nav-link i {
line-height: normal;
}
.sidebarMain .nav-link img {
object-fit: contain;
object-position: center;
}
.sidebarMain .nav-link:hover, 
.sidebarMain .nav-link.active {
color: #10b068;
}

.sidebarMain .nav-link:hover::after,
.sidebarMain .nav-link.active::after {
content: "";
float: left;
width: 2px;
height: 100%;
background-color: #10b068;
position: absolute;
left: 0;
top: 0;
}
.sidebarMain .nav-link .active {
display: none;
}
.sidebarMain .nav-link:hover .inactive, 
.sidebarMain .nav-link.active .inactive {
display: none;
}
.sidebarMain .nav-link:hover .active,
.sidebarMain .nav-link.active .active  {
display: block;
}
.head_section {
float: left;
width: 100%;
background-color: #fff;
border-bottom: 1px solid #ddd;
height: 58px;
display: flex;
justify-content: space-between;
align-items: center;
padding: 0px 15px 0px 0px;
}
.head_section a.nav-logo {
float: left;
width: auto;
}
.head_section a.nav-logo img {
width: 150px;
}
.dropdownHeader {
float: right;
width: auto;
}
.dropdownHeader .dropdown i{
margin-right: 4px;
}
.dropdownHeader .dropdown .dropdown-toggle {
padding: 0px;
background: transparent !important;
color: #333 !important;
border: 0px;
border-radius: 0px;
line-height: normal;
box-shadow: none !important;
outline: none !important;
display: flex;
align-items: center;
column-gap: 8px;
}
.sidebarWrapper .sidebar {
float: left;
width: calc(100% - 50px);
height: 100%;
background-color: #fff;
}
.sidebarWrapper .sidebar .flex-column .nav-link {
padding: 14px 12px;
color: #333;
font-size: 14px;
line-height: normal;
display: flex;
column-gap: 10px;
align-items: center;
justify-content: flex-start;
position: relative;
}
.sidebarWrapper .sidebar .flex-column .nav-link span {
float: left;
width: auto;
position: absolute;
right: 10px;
top: 15px;
font-size: 12px;
}
.sidebarWrapper .sidebar .flex-column .nav-link.active, 
.sidebarWrapper .sidebar .flex-column .nav-link:hover {
background-color: #10b068;
color: #fff;
}
.collapnseDrop  {
background-color: #eee;
border-bottom: 1px solid #eee;
}
.sidebarWrapper .sidebar .flex-column .collapnseDrop ul {
padding: 0px;
margin: 0px;
}
.sidebarWrapper .sidebar .flex-column .collapnseDrop ul li {
float: left;
width: 100%;
font-size: 14px;
list-style: none;
}
.navLogo {
float: left;
border-right: 1px solid #ddd;
height: 100%;
padding: 10px;
display: flex;
align-items: center;
justify-content: flex-start;
}
.pageTitle {
float: left;
width: auto;
text-transform: capitalize;
flex: 1;
font-size: 15px;
margin: 0px;
padding-left: 20px;
}
/* sidebar css */

/* login page css start here */
.loginWrapper {
float: left;
width: 100%;
position: relative;
height: 100vh;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
background-color: #fff;
background-image: url("../img/login_background.jpg");
background-position: bottom;
background-size: cover;
background-repeat: no-repeat;
}
.logoheader {
  color: #10b068;
  font-weight: 700;
  letter-spacing: 0.8px;
  margin: 0 0 20px;
  font-size: 39px;
  text-align: center;
  float: left;
  width: 100%;
}
.logoheader img {
  width: 250px;
}
.loginMainblog {
  float: left;
  width: 410px;
  background: #fff;
  padding: 25px 35px 35px;
}
.loginMainblog h4 {
float: left;
width: 100%;
font-size: 20px;
margin: 0 0 15px;
}
.mainloginwrps {
float: left;
width: 100%;
height: 100%;
}
.formloginset .form-label {
float: left;
width: 100%;
font-size: 14px;
}
.mb-3, .mb-4 {
float: left;
width: 100%;
}
.formloginset input {
border-radius: 0px;
height: 38px;
font-size: 13px !important;
box-shadow: none !important;
}
.widthfullbtn {
width: 100%;
}
.formloginset select {
width: 100%;
border-radius: 0px;
height: 38px;
font-size: 13px;
padding: 10px 10px;
border-color: #ced4da;
outline: none;
box-shadow: none !important;
background-image: url("../img/caret-down.svg");
background-size: 10px;
background-repeat: no-repeat;
background-position: 96% center;
-webkit-appearance: none;
-moz-appearance: none;
appearance: none;
}
small.note {
font-size: 13px;
margin: 5px 0 0;
color: #7a7a7a;
}
.formloginset .btn {
background-color: #10b068 !important;
border-color: #10b068 !important;
border-radius: 0px;
padding: 8px 10px;
/* margin: 5px 0 0; */
width: 100%;
position: relative;
overflow: hidden;
z-index: 9;
}
body .btn.button-1{
  background-color: #6c757d !important;
  border-color: #6c757d !important;
  border-radius: 0px;
  padding: 8px 10px;
  width: 100%;
  position: relative;
  overflow: hidden;
  z-index: 9;
  color: white!important;
}
body .btn.button-1.btn-sm {
  font-size: 14px !important;
  border-radius: 0px !important;
  padding: 6.6px 10px !important;
}
body .btn.button-1:focus{
  background-color: #7f878f!important;
}
.signInbtn::after {float: left;width: 100%;height: 100%;position: absolute;left: 0;top: 0;z-index: -1;content: "";background-color: #0E0E30;border-color: #0E0E30;transform: translateY(100%);-webkit-transition: 0.5s;-moz-transition: 0.5s;-o-transition: 0.5s;-ms-transition: 0.5s;transition: 0.5s;}
.signInbtn:hover::after {transform: translateY(0%);-webkit-transition: 0.5s;-moz-transition: 0.5s;-o-transition: 0.5s;-ms-transition: 0.5s;transition: 0.5s;}
.passwordfiled {
float: left;
width: 100%;
position: relative;
}
#searchbtn {
  margin: 0px;
}
.passwordfiled input {
padding-right: 35px;
}
.passwordfiled .showpassbtn {
  width: 18px;
  height: 18px;
  position: absolute;
  right: 12px;
  top: 12px;
  cursor: pointer;
}
.passwordfiled .showpassbtn svg {
float: left;
width: 100%;
}
.danger-colorset {
font-size: 12px;
color: #F84646;
float: left;
width: 100%;
margin: 4px 0 0;
}
/* login page css end here */

/* pannel css */
.panelwrapper {
float: left;
width: calc(100% - 301px);
height: calc(100vh - 58px);
padding: 10px;
position: relative;
}
.panelContentwrapper {
float: left;
width: 100%;
height: 100%;
background-color: #fff;
padding: 10px;
}
.sidebarWrapper .sidebar .flex-column .collapnseDrop ul li a {
float: left;
width: 100%;
text-decoration: none;
color: #333;
padding: 13px 15px 13px 35px;
font-size: 13px;
position: relative;
}
.sidebarWrapper .sidebar .flex-column .collapnseDrop ul li a:hover,
.sidebarWrapper .sidebar .flex-column .collapnseDrop ul li a.active {
background-color: #ccc;
}
.sidebarWrapper .sidebar .flex-column .collapnseDrop ul li a:after {
content: "o";
float: left;
font-weight: 500;
left: 14px;
position: absolute;
top: 12px;
width: auto;
}
#button-tooltip {
padding: 0px !important;
}
/* pannel css */

/* table filter */
.headerbtngroups {
float: left;
width: auto;
align-items: center;
display: flex;
}
.export_btncmn {
font-size: 14px;
padding: 6px 14px;
margin: 0 0 5px;
position: relative;
cursor: pointer;
display: flex;
align-items: center;
background-color: var(--loginColor) !important;
color: #fff !important;
margin-left: 10px;
}
.export_btncmn:hover {
background-color: #2769ff !important;
}
.graphs_columns {
float: left;
width: 100%;
position: relative;
margin: 5px 0 25px;
}
.productQuantyset {float: left;width: auto;display: flex;align-items: center;border: 1px solid #ddd;border-radius: 10px;overflow: hidden;background-color: #eee;max-width: 130px;margin: -5px 0 0;}
.cart_wrappperr li .cartbtn_wraps .quantity_product .productQuantyset {width: 100%;display: flex;justify-content: space-between;background: transparent;border: 0px;font-size: 18px;}
.productQuantyset .btn {padding: 5px 5px;outline: none !important;border: 0px !important;margin: 0px 5px;}
.productQuantyset .btn i {color: #6e6c6c;}
.productQuantyBox {float: left;width: auto;word-break: break-all;}
.redmednstar {color: red;font-size: 19px;line-height: 10px;display: inline-block;position: relative;top: 3px;}
.addQuantity i {color: var(--loginColor) !important;}
.graphs_columns h4 {float: left;width: 100%;font-size: 17px;margin: 0 0 20px;}
.piechartwraps {float: left;}
.card-header-New {float: left;margin: 0 0 15px;width: 100%;}
.card-header-New select {height: 36px !important;border-radius: 0px !important;font-size: 13px;}
.searchBoxwithbtn {float: left;position: relative;width: 260px;}
.searchBoxwithbtn .form-control {border: 1px solid #ddd;border-radius: 0;box-shadow: none!important;font-size: 13px;height: 36px;padding: 7px 35px 7px 27px;}
.searchBoxwithbtn.search .form-control{padding-right: 55px;}
.searchBoxwithbtn .form-control:not( ~ .searchbtn ){padding-right: 0px!important;}
.searchBoxwithbtn .searchbtn, .searchBoxwithbtn .searchbtn:focus {
position: absolute;
right: 0px;
top: 0px;
border-radius: 0px;
height: 100%;
width: 32px;
padding: 0px;
display: flex;
align-items: center;
justify-content: center;
background-color: #10b068;
border-color: #10b068;
}
.searchBoxwithbtn .searchbtn:hover {
background-color: #0E0E30 !important;
border-color: #0E0E30 !important;
}
.searchBoxwithbtn .icon-tabler-search {
width: 19px;
}
.serachBoxWIth .searchbtn, .serachBoxWIth .searchbtn:focus {
position: absolute;
right: 0px;
top: 0px;
border-radius: 0px;
height: 100%;
width: 32px;
padding: 0px;
display: flex;
align-items: center;
justify-content: center;
background-color: #10b068;
border-color: #10b068;
margin: 0 0 0 !important;
}
.serachBoxWIth .searchbtn:hover {
background-color: #0E0E30 !important;
border-color: #0E0E30 !important;
}
.serachBoxWIth .icon-tabler-search {
width: 19px;
}
.card-header-right {float: right;width: auto;display: flex;}
.activestatusselet, .userLimitselect {background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAAsQAAALEBxi1JjQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAACOSURBVEiJ7c/LCoFRFIDR5WEkKUlJiZRiwMCzmngjiQi5ZcSAM5HLfxtI/1d7cNq12oe8vJ+v8PQeY5jSnGLyblnEDNeEs0D52wUlzBPgS1SifrOKVQx8g3pUPFTDOgK+RSMuHmpi9wE/oJMUD7Wwf4Ef0U2Lh9ru1wb8hF5WeKiPMy4YZY2HBo/Jy/unbpb0TLQqPZ0IAAAAAElFTkSuQmCC);background-position: 91%;background-repeat: no-repeat;background-size: 13px;border: 1px solid #ddd!important;border-radius: 0;cursor: pointer;float: left;font-size: 14px;height: 36px;width: 90px !important;}
.selectuserteamDrop {background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAAsQAAALEBxi1JjQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAACOSURBVEiJ7c/LCoFRFIDR5WEkKUlJiZRiwMCzmngjiQi5ZcSAM5HLfxtI/1d7cNq12oe8vJ+v8PQeY5jSnGLyblnEDNeEs0D52wUlzBPgS1SifrOKVQx8g3pUPFTDOgK+RSMuHmpi9wE/oJMUD7Wwf4Ef0U2Lh9ru1wb8hF5WeKiPMy4YZY2HBo/Jy/unbpb0TLQqPZ0IAAAAAElFTkSuQmCC);background-position: 91%;background-repeat: no-repeat;background-size: 13px;border: 1px solid #ddd!important;border-radius: 0;cursor: pointer;float: left;font-size: 14px;height: 36px;width: 200px !important;}
.card-header-right select:first-child {margin-right: 10px;}
.card-header-right select:nth-child(2) {margin-right: 10px;}
.card-header-right select {box-shadow: none!important;}
.head_cmn_btn {font-size: 14px !important;border-radius: 0px !important;padding: 6.6px 10px !important;background-color: #0E0E30 !important;color: #fff !important;border-color: var(--loginColor) !important;}
.head_cmn_btn:hover {background-color: #333 !important;}
.head_reset_btn, .head_reset_btn:focus, .head_reset_btn:active {font-size: 14px;border-radius: 0px;margin: 0 0 0 10px;padding: 6.6px 10px;background-color: red !important;color: #fff !important;border-color: red !important;}
.head_reset_btn:hover {background-color: red !important;}
.cmn_export_btn, .cmn_export_btn:focus, .cmn_export_btn:active {font-size: 14px;border-radius: 0px;margin: 0 0 0 10px;padding: 6.6px 10px;background-color: #00b13a !important;color: #fff !important;border-color: #00b13a !important;}
.cmn_export_btn:hover {background-color: #008d21 !important;}
.cmn_export_btn img {width: 18px;line-height: normal;margin: 0px 3px 0px 0px;}
.head_cmn_btn i {margin: 0 4px 0 0px;}
.head_reset_btn i {margin: 0 4px 0 0px;}
.searchBoxwithbtn .searchclear {
float: right;
width: auto;
position: absolute;
top: 9px;
right: 40px;
font-size: 13px;
cursor: pointer;
color: red;
background-color: #fff;
}
/* table filter */

/* table css  */
.tableView {float: left;width: 100%;position: relative;}
.tableView thead tr th {font-size: 13px;font-weight: 400;padding: 10px 10px;background-color: #f6f6f6;color: #212529;position: relative;}
.tableView thead tr th.active {
  color: #10b068;
  font-weight: 500;
}
.tableView thead tr th.inactive {
  color: red;
  font-weight: 500;
}
.gstreporttableadded thead tr th, .gstreporttableadded tbody tr td {
  white-space: nowrap;
}
/* .orderlistTable thead tr th, .orderlistTable tbody tr td {
  white-space: nowrap;
} */
.Main_sectionGSTReports .card-header-New {
  width: auto;
  position: absolute;
  right: 0;
  top: 0;
}
.tableView tbody {border-top: 0px !important;}
.tableView tbody tr td {font-size: 13px;vertical-align: middle;color: #212529;}
.AvtarMain {float: left;width: auto;border-radius: 0.375rem;border: 1px solid #eee;overflow: hidden;}
.AvtarMain img {width: 60px;}
.table-hover>tbody>tr:hover>* {background-color: transparent !important;--bs-table-accent-bg: none !important;--bs-table-bg-state: transparent !important;}
.badge {
font-size: 12px !important;
font-weight: 500 !important;
letter-spacing: 0.5px !important;
padding: 8px 10px !important;
}
.badge.bg-success {
background-color: #eaf7ec !important;
color: #2aa13d !important;
}
.badge.bg-danger {
background-color: #fbebeb !important;
color: #dc3545 !important;
}
.edittable  {
border-radius: 0px !important;
padding: 0px !important;
line-height: normal !important;
font-size: 17px !important;
box-shadow: none !important;
background-color: transparent !important;
color: #000 !important;
border: 0px !important;
outline: none !important;
}
.edittable i {
line-height: normal;
}
.exportbtn {
background-color: #10b068 !important;
color: #fff !important;
font-size: 14px !important;
border-radius: 0px !important;
padding: 6.6px 10px !important;
margin-right: 10px;
}
.exportbtn i {
margin: 0 4px 0 0px;
}
/* table css  */

/* common toggle css */
.comnToggle {float: left;width: auto;}
.comnToggle i {font-size: 25px;cursor: pointer;line-height: normal;}
.comnToggle .bi-toggle2-on {color: #008fff;}
/* common toggle css */

/* form css */
.tabsWrappers {
float: none;
width: 100%;
max-width: 75%;
margin: 15px auto 0;
display: table;
padding: 0px 60px;
position: relative;
border-top: 0px;
border-bottom: 0px;
}
.formloginset .form-label {
float: left;
width: 100%;
font-size: 14px;
}
.mb-3 {
float: left;
width: 100%;
}
.formloginset textarea {
border-radius: 0px;
height: 120px;
font-size: 13px;
box-shadow: none !important;
resize: none;
}
.formloginset .form-control[type=file] {
line-height: 29px;
}
.widthfullbtn {
width: 100%;
}
.formloginset select {
width: 100%;
border-radius: 0px;
height: 38px;
font-size: 13px;
padding: 6px 10px;
border-color: #ced4da;
outline: none;
box-shadow: none !important;
background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAAsQAAALEBxi1JjQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAACOSURBVEiJ7c/LCoFRFIDR5WEkKUlJiZRiwMCzmngjiQi5ZcSAM5HLfxtI/1d7cNq12oe8vJ+v8PQeY5jSnGLyblnEDNeEs0D52wUlzBPgS1SifrOKVQx8g3pUPFTDOgK+RSMuHmpi9wE/oJMUD7Wwf4Ef0U2Lh9ru1wb8hF5WeKiPMy4YZY2HBo/Jy/unbpb0TLQqPZ0IAAAAAElFTkSuQmCC);
background-size: 10px;
background-repeat: no-repeat;
background-position: 96% center;
-webkit-appearance: none;
-moz-appearance: none;
appearance: none;
}
.tablecollapsewraps {
background-color: transparent !important;
}
.tablecollapsewraps table th {
background-color: #f8f8f8;
font-size: 12px;
padding: 10px 12px;
font-weight: 500;
}
.tablecollapsewraps table td {
font-size: 12px;
}
.formData {
float: left;
width: 100%;
font-size: 14px;
}
.formsearchList {
float: left;
width: 100%;
border-bottom: 1px solid #eee;
margin-bottom: 12px;
}
.formbtn {
background-color: #10b068 !important;
border-color: #10b068 !important; 
border-radius: 0px !important;
padding: 8px 25px !important;
margin: 5px 0 0 !important;
position: relative;
overflow: hidden;
z-index: 9;
}
.ToggleMainWrap {
display: flex;
float: left;
width: 100%;
align-items: center;
justify-content: flex-start;
column-gap: 10px;
}
.formloginset {
float: left;
width: 100%;
position: relative;
}
.formloginset .HeaderDivider:nth-last-child(2) {
margin: 19px 0 18px;
}
.HeaderDivider {
float: left;
width: 100%;
padding: 30px 15px 10px 15px;
border: 1px solid #eee;
margin: 19px 0 30px;
position: relative;
}
.HeaderDivider h6.subtitleForm {
float: left;
width: auto;
position: absolute;
background-color: #f9fafb;
padding: 10px 15px;
font-size: 14px;
top: -18px;
left: 15px;
margin: 0px;
border: 1px solid #eee;
}
.btnGroupswraps {
float: left;
width: 100%;
display: flex;
column-gap: 10px;
margin-bottom: 10px;
}
.btnGroupswraps .Resetbtn {
background-color: #0E0E30 !important;
border-color: #0E0E30 !important;
border-radius: 0px !important;
padding: 8px 10px !important;
margin: 5px 0 0 !important;
position: relative;
overflow: hidden;
z-index: 9; 
width: auto !important;
}
#GetDetailsbntn {
margin-top: 0px !important;
}
.filtr_Form {
  float: left;
  width: 100%;
  margin-bottom: 15px;
}
.datepickField {
float: left;
width: 100%;
position: relative;
}
.datepickField input {
border-radius: 0px;
height: 38px;
width: 100%;
font-size: 13px;
box-shadow: none !important;
border: 1px solid #dee2e6 !important;
padding: .375rem .75rem;
outline: none !important;
background-image: url("../img/calendar.png");
background-repeat: no-repeat;
background-size: 16px;
background-position: 90% center;
}
.datepickField.min input {
background-position: 96% center;
}
.Addbtn {
background-color: #10b068 !important;
border-color: #10b068 !important;
border-radius: 0px !important;
padding: 8px 10px !important;
margin: 5px 0 0 !important;
position: relative;
overflow: hidden;
z-index: 9;
width: auto !important;
}
.react-datepicker-wrapper {
width: 100%;
}
.card-header-New .formloginset input {
height: 36px !important;
}
.totaltd th {
background-color: #f6f6f6 !important;
color: #212529 !important;
font-size: 13px;
font-weight: 500;
padding: 10px 10px !important;
}

.selectMultiselect .godown-select__input-container.css-qbdosj-Input,
.selectMultiselect .godown-select__input-container {
margin: 0px;
padding-bottom: 0px;
padding-top: 0px;
}
.selectMultiselect .godown-select__value-container.css-1fdsijx-ValueContainer {
padding: 0px 8px !important;
}
.selectMultiselect .godown-select__control.css-13cymwt-control, .selectMultiselect .css-t3ipsp-control {
border-color: #dee2e6 !important;
border-radius: 0px;
outline: none !important;
box-shadow: none !important;
}
.selectMultiselect .css-13cymwt-control, .selectMultiselect .css-1jqq78o-placeholder, .selectMultiselect .css-1dimb5e-singleValue {
font-size: 13px !important;
}
.formloginset #selectMultiselectBrand input{
height: 28px !important;
}

.formloginset .whloginhbtn{
  width:100%;
  padding:5px !important;
  margin:0px !important
}

.formloginset .getDetailButton{
  width:100px;
  padding:7px !important;
  margin:0px !important
}
/* form css */

/* image upload  */
.uploadfielsbuttons .uploadfilebuttons {margin-bottom: 17px;}
.imageuploads {float: left;width: 100px;height: 100px;border-radius: 20px;position: relative;border: 1px solid #ddd;}
.imageuploads img {float: left;width: 100%;height: 100%;border-radius: 20px;object-fit: cover;object-position: center;}
.imageuploads .closeIcon {float: left;width: 17px;height: 17px;position: absolute;right: -2px;top: -2px;cursor: pointer;background-color: #fff;border-radius: 100%;}
.imageuploads .closeIcon::after {content: "";float: left;height: 100%;width: 100%;-webkit-mask-image: url("../img/closebutton.png"); mask-image: url("../img/closebutton.png"); mask-position: center; -webkit-mask-position: center; mask-repeat: no-repeat; -webkit-mask-repeat: no-repeat; mask-size: contain; -webkit-mask-size: contain; background-color: red !important;}

.pdffileviewo .closeIcon {float: left;width: 17px;height: 17px;position: absolute;right: -7px;top: -3px;cursor: pointer;background-color: #fff;border-radius: 100%;}
.pdffileviewo .closeIcon::after {content: "";float: left;height: 100%;width: 100%;-webkit-mask-image: url("../img/closebutton.png"); mask-image: url("../img/closebutton.png"); mask-position: center; -webkit-mask-position: center; mask-repeat: no-repeat; -webkit-mask-repeat: no-repeat; mask-size: contain; -webkit-mask-size: contain; background-color: red !important;}

.fileloader {float: left;width: 28px;height: 28px;position: absolute;right: -6px;background-color: #fff;border-radius: 100%;top: -5px;padding: 5px;box-shadow: 0px 0px 17px -3px #ccc;}
.fileloader img {float: left;width: 100%;height: 100%;object-fit: contain;object-position: center;}
.fileloader.active {padding: 0px;}
.uploadbuttons {float: left;width: 100px;height: 100px;position: relative;cursor: pointer;border: 1px solid #eee;border-radius: 20px;}
.uploadbuttons .uploadfilebox img {float: left;height: 100%;width: 100%; border-radius: 20px;}
.uploadfilebox img {float: left;height: 100%;width: 100%; border-radius: 20px;object-fit: cover;object-position: top;}
.uploadbuttons input.uploadfilInput {position: absolute;visibility: hidden;height: 100%;width: 100%;left: 0;top: 0;}
.uploadbuttons .fileloader label {float: left;width: 100%;height: 100%;cursor: pointer;}
.uploadbuttons .fileloader label img {float: left;width: 100%;height: 100%;object-fit: contain;object-position: center;}
.error_medotory {float: left;width: 100%;font-size: 13px;color: red;}
.uploadfilebox {float: left;width: 100%;height: 100%;}
/* image upload  */

/* side content css */
.MainHeader {
float: left;
width: 100%;
margin-bottom: 15px;
padding-bottom: 7px;
text-align: right;
border-bottom: 1px solid #ddd;
}
.MainHeader .backbtn {
padding: 0px 10px;
font-size: 13px;
background: transparent !important;
box-shadow: none !important;
text-decoration: none;
color: #333;
}
.FranchiseWallet .backbtn {
padding: 0px 10px;
font-size: 13px;
background: transparent !important;
box-shadow: none !important;
text-decoration: none;
color: #333;
}
.selectMultiselect {
float: left;
width: 100%;
position: relative;
}
.searchWrapper {
min-height: 12px !important;
padding: 3px 10px !important;
border-radius: 0px !important;
border: 1px solid #ddd !important;
}
.mendatory_star {
color: red;
}
.multiSelectContainer {
background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAAsQAAALEBxi1JjQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAACOSURBVEiJ7c/LCoFRFIDR5WEkKUlJiZRiwMCzmngjiQi5ZcSAM5HLfxtI/1d7cNq12oe8vJ+v8PQeY5jSnGLyblnEDNeEs0D52wUlzBPgS1SifrOKVQx8g3pUPFTDOgK+RSMuHmpi9wE/oJMUD7Wwf4Ef0U2Lh9ru1wb8hF5WeKiPMy4YZY2HBo/Jy/unbpb0TLQqPZ0IAAAAAElFTkSuQmCC);
background-position: 95%;
background-repeat: no-repeat;
background-size: 13px;
}
.multiSelectContainer input {
height: 33px !important;
margin-top: 0px !important;
}
.selectMultiselect.removeBackground .multiSelectContainer {
background-image: none !important;
}
.chip {
padding: 3px 9px !important;
}
.optionListContainer {
z-index: 99 !important;
}
.MainHeader_Tab {
float: left;
width: auto;
padding: 0px;
margin: 0px;
display: flex;
align-items: center;
column-gap: 15px;
}
.MainHeader_Tab li {
list-style: none;
font-size: 15px;
cursor: pointer;
font-weight: 500;
position: relative;
}
.MainHeader_Tab li:hover, .MainHeader_Tab li.active {
color: #10b068;
}
.MainHeader_Tab li:hover::after, .MainHeader_Tab li.active::after {
content: "";
float: left;
width: 100%;
height: 3px;
background-color: #10b068;
position: absolute;
left: 0;
bottom: -11px;
}
/* side content css */

.signInbtn .spinner-border {
height: 21px;
width: 21px;
border-width: 3px;
}
.commonModal .notformsection {
text-align: center;
line-height: 28px;
font-size: 17px;
}
.commonModal .notformsection .logoutIconbtn {
font-size: 25px;
color: red;
}
.commonModal .modal-dialog .modal-content {
border-radius: 0px !important;
}
.commonModal .modal-dialog .modal-footer .btn-secondary {
border-radius: 0px !important;
font-size: 15px;
padding: 8px 18px;
}
.commonModal .modal-dialog .modal-footer .addbtncmn  {
border-radius: 0px !important;
font-size: 15px;
padding: 8px 18px;
background-color: #10b068 !important;
}
.commonModal .modal-dialog .modal-content .modal-header .btn-close {
box-shadow: none !important;
outline: none !important;
}
.addTableModal .modal-dialog{
  max-width: 780px;
}
.StockInwardLogDetailsTable .modal-dialog{
  max-width: 900px;
}
.OrderDetailsModal .notformsection {
text-align: center;
line-height: 28px;
font-size: 17px;
}
.downloadaction {
color: #333;
font-size: 18px;
float: right;
}
.productbilladdform {
  float: left;
  width: 100%;
  text-align: left;
  padding: 13px 20px;
  border: 1px solid #eee;
  border-radius: 10px;
  margin: 10px 0 0;
}
.StockDetailsReportPage .card-header-New .card-header-right .exportbtn {
display: none;
}
.OrderDetailsModal .notformsection .logoutIconbtn {
font-size: 25px;
color: red;
}
.OrderDetailsModal .modal-dialog .modal-content {
border-radius: 0px !important;
}
.OrderDetailsModal .modal-dialog .modal-footer .btn-secondary {
border-radius: 0px !important;
font-size: 15px;
padding: 8px 18px;
}
#approvlaMargin {
  margin-top: 0px !important;
}
.isdemo_flags {
  font-size: 11px !important;
  font-weight: 500 !important;
  letter-spacing: 0.5px !important;
  padding: 6px 8px !important;
  border-radius: 4px;
}
#columnfullwith {
  width: 100% !important;
}
.OrderDetailsModal input {
  border-radius: 0px;
  box-shadow: none !important;
}
.OrderDetailsModal .modal-dialog .modal-footer .addbtncmn  {
border-radius: 0px !important;
font-size: 15px;
padding: 8px 18px;
background-color: #10b068 !important;
}
.OrderDetailsModal .modal-dialog .modal-content .modal-header .btn-close {
box-shadow: none !important;
outline: none !important;
}
.OrderDetailsModal .modal-dialog {
max-width: 65%;
}

.errorDetailsModal .notformsection {
text-align: center;
line-height: 28px;
font-size: 17px;
}
.errorDetailsModal .notformsection .logoutIconbtn {
font-size: 25px;
color: red;
}
.errorDetailsModal .modal-dialog .modal-content {
border-radius: 0px !important;
}
.errorDetailsModal .modal-dialog .modal-footer .btn-secondary {
border-radius: 0px !important;
font-size: 15px;
padding: 8px 18px;
}
.errorDetailsModal .modal-dialog .modal-footer .addbtncmn  {
border-radius: 0px !important;
font-size: 15px;
padding: 8px 18px;
background-color: #10b068 !important;
}
.errorDetailsModal .modal-dialog .modal-content .modal-header .btn-close {
box-shadow: none !important;
outline: none !important;
}
#GetDetailsbntn.filterBlackbtn{
background-color: #0E0E30 !important;
border-color: #0E0E30 !important;
border-radius: 0px !important;
padding: 7.5px 10px !important;
margin: 0px 0 0 10px !important;
position: relative;
overflow: hidden;
z-index: 9;
width: auto !important;
}
/* pagination css start here */
.paginationCustom {float: left;width: 100%;padding: 0px;margin: 9px 0px 0px;}
.paginationCustom li:first-child button, .paginationCustom li:last-child button,
.paginationCustom li:nth-child(2) button, .paginationCustom li:nth-last-child(2) button {
  color: transparent;
  position: relative;
}
.paginationCustom li:first-child button::before {
  content: "\F22D";
  font-family: 'bootstrap-icons';
  color: #333;
  opacity: 1;
  font-size: 17px;
  position: absolute;
  left: 6px;
  line-height: normal;
}
.paginationCustom li:nth-child(2) button::before {
  content: "\F129";
  font-family: 'bootstrap-icons';
  color: #333;
  opacity: 1;
  font-size: 20px;
  position: absolute;
  left: 5px;
  line-height: normal;
}
.paginationCustom li:last-child button::before {
  content: "\F133";
  font-family: 'bootstrap-icons';
  color: #333;
  opacity: 1;
  font-size: 21px;
  position: absolute;
  left: 0;
  line-height: normal;
}
.paginationCustom li:nth-last-child(2) button::before {
  content: "\F133";
  font-family: 'bootstrap-icons';
  color: #333;
  opacity: 1;
  font-size: 20px;
  position: absolute;
  right: 5px;
  line-height: normal;
}
.paginationCustom li:last-child button::before {
  content: "\F231";
  font-family: 'bootstrap-icons';
  color: #333;
  opacity: 1;
  font-size: 17px;
  position: absolute;
  right: 0;
  line-height: normal;
}
.paginationCustom li .rpb-item--active {background-color: #10b068; color: #fff !important;}
.paginationCustom li button {width: 29px;height: 29px;min-width: 29px;font-size: 14px;border-radius: 100%;}
/* pagination css end here */

.godown-select__menu {z-index: 9999 !important;}
.Bigform_custom_scroll {
float: left;
width: 100%;
overflow-x: hidden;
overflow-y: auto;
padding-right: 5px;
}
.selectMultiselectCategory .godown-select__input {
height: 39px !important;
}
.godown-select-container .selectMultiselectBrand .godown-select__input {
height: 40px !important;
}

#selectMultiselectBrand .css-16xfy0z-control {
  border-radius: 0px !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}

/* Firefox */
input[type=number] {
-moz-appearance: textfield;
}

.tableView tbody .btn-link {
padding: 0px;
font-size: 13px;
background: transparent !important;
}
.tableView tbody .btn-link-color {
  color: #0d6efd !important;
  cursor: pointer;
  font-weight: 400;
}
#activestatusselet {
width: 100% !important;
}
#FranchiseUserReportWidth {
width: 130px !important;
}
.commonModal.addBoxModalNew .modal-dialog {
  max-width: 680px;
}
#FranchiseUserReportWidthNew {
  width: 230px !important;
}
.newsumbitbnt  {
background-color: #10b068 !important;
color: #fff !important;
font-size: 14px !important;
border-radius: 0px !important;
padding: 6.6px 10px !important;
margin-right: 10px;
}
.table-description {
height: 90px;
width: 180px !important;
border-radius: 0px !important;
box-shadow: none !important;
}
.cancelBtn {
background-color: #10b068 !important;
color: #fff !important;
font-size: 14px !important;
border-radius: 0px !important;
padding: 6.6px 10px !important;
}
.tableBolanchor {
color: #00f;
cursor: pointer;
}
.ScrollbarsSidebar .view {
margin-right: -17px !important;
padding-right: 4px;
overflow-x: hidden !important;
margin-bottom: 0px !important;
}
.sotklevelbtn {
float: right;
position: relative;
right: 20px;
}
.serachBoxWIth {
float: left;
width: 100%;
position: relative;
}
.serachBoxWIth .spinner-border {
position: absolute;
right: 40px;
top: 9px;
height: 20px;
width: 20px;
border-width: 3px;
}
.defaultvalueField {
float: left;
width: 100%;
border: 1px solid #ddd;
height: 40px;
display: flex;
align-items: center;
padding: 10px;
font-size: 13px;
cursor: no-drop;
background-color: #eee;
}
.defaultvalueFieldaddress {
float: left;
width: 100%;
border: 1px solid #ddd;
padding: 10px;
font-size: 13px;
cursor: no-drop;
background-color: #eee;
}
.defaultvalueFieldaddress ul {
padding-left: 15px;
margin-bottom: 0px;
}
.defaultvalueFieldaddress ul li {
margin-bottom: 3px;
font-size: 13px;
}
.defaultvalueFieldaddress ul li:last-child {
margin-bottom: 0px;
}
.pincode_error {
float: left;
width: auto;
position: absolute;
right: 42px;
top: 6px;
cursor: pointer;
}
.filedinfomsg {
font-size: 12px;
float: right;
margin-right: 10px;
cursor: pointer;
}
.UploadCmnBTN {
position: relative;
}
.UploadCmnBTN input[type="file"] {
position: absolute;
left: 0;
top: 0;
visibility: hidden;
}
.leftFormheader {
float: left;
width: inherit;
}
#UploadCmnBTN {
background-color: #0e0e30!important;
border-color: #0e0e30!important;
border-color: var(--loginColor)!important;
border-radius: 0!important;
color: #fff!important;
font-size: 14px!important;
padding: 6.6px 10px!important;
}
.UploadLoader {
float: left;
width: auto;
margin: 5px 0px 0px 10px;
}
.UploadLoader .spinner-border {
height: 23px;
width: 23px;
border-width: 4px;
}
.uplaodedFilename {
float: left;
width: auto;
padding: 7px 20px 7px 34px;
font-size: 14px;
word-break: break-word;
background-color: #eee;
border-radius: 30px;
border: 1px solid #ddd;
display: flex;
align-items: center;
position: relative;
}
.exsting_newdetails {
float: left;
width: 100%;
margin: 15px 0 0;
}
.exsting_newdetails h4 {font-size: 15px;margin: 0 0 12px;}
.uplaodedFilename i:first-child {
font-size: 20px;
position: absolute;
left: 8px;
}
.uplaodedFilename .bi-x-circle-fill {
color: red;
position: absolute;
right: -5px;
top: 8px;
z-index: 99;
background-color: #fff;
line-height: normal;
border-radius: 100%;
font-size: 18px;
cursor: pointer;
height: 19px;
width: 19px;
}
.uplaodedFilename .bi-x-circle-fill::before {
vertical-align: baseline;
}
.ScrollbarsSidebarHide .formloginset {
padding: 0px 15px 0px 0px;
}
.btn.disabled {
cursor: no-drop !important;
}
.actionbtn {
float: left;
width: 17px;
height: 17px;
padding: 0px !important;
border: 0px !important;
outline: none !important;
box-shadow: none !important;
background: transparent !important;
-webkit-transition: 0.2s;
-moz-transition: 0.2s;
-ms-transition: 0.2s;
-o-transition: 0.2s;
transition: 0.2s;
}
.actionbtn img {
float: left;
height: 100%;
width: 100%;
}
.actionbtn.active img {
transform: rotate(180deg);
-webkit-transition: 0.2s;
-moz-transition: 0.2s;
-ms-transition: 0.2s;
-o-transition: 0.2s;
transition: 0.2s;
}
.panelContentwrapper .table tbody .actionbtn {
margin-right: 12px;
}
.panelContentwrapper .table tbody .actionbtn:last-child {
margin-right: 0px;
}
.table-bordered>:not(caption)>* {
border-color: #dee2e6 !important;
}
.tablecollapsewraps .table {
margin-bottom: 0px !important;
}
.datepickField .react-datepicker__close-icon {
right: 38px;
}
.datepickField .react-datepicker__close-icon::after {
background-color: red;
border-radius: 100% !important;
height: 13px !important;
width: 13px !important;
padding: 1px 1px !important;
font-size: 12px !important;
line-height: 0.5 !important;
}
.react-datepicker .react-datepicker__month-select {
height: auto !important;
padding: 1px 5px;
border-radius: 8px;
}
.react-datepicker .react-datepicker__year-select {
height: auto !important;
padding: 1px 7px;
border-radius: 8px;
width: 70px;
}
#mb-3 {
margin-bottom: 7px !important;
}
.filtercollapsebtn {
background-color: #0E0E30;
color: #fff;
font-size: 14px;
padding: 6px 9px;
}
#stocklevelExportbtn {
width: auto;
margin: 0px 7px 0px;
padding: 7.5px 10px !important;
}
#PurchaseOrderbtn {
  width: auto;
  margin: 0px;
  padding:7.5px 25px !important;
}
#PurchaseOrderClearbtn {
  width: auto;
  margin: 0px;
  background-color: #0E0E30 !important;
  padding:7.5px 15px !important;
}
#PurchaseOrderbtnnew {
  width: auto;
  margin: 0px;
  padding: 7.5px 11px !important;
}
#PurchaseOrderClearbtnnew {
  width: auto;
  margin: 0px;
  background-color: #0E0E30 !important;
  padding: 7.5px 9px !important;
}
#PurchaseOrderClearbtnnew i {
  margin: 0px !important;
}
#userLimitselect {
width: 85px !important;
}
.notformsection ul {
text-align: left;
margin: 0px;
padding: 10px 10px;
background-color: #eee;
border-radius: 10px;
}
.notformsection ul li {
font-size: 16px;
padding-bottom: 9px;
display: flex;
align-items: center;
justify-content: center;
column-gap: 9px;
}
.notformsection ul li:last-child {
padding-bottom: 0px;
}
.notformsection ul li span {
float: left;
width: 24px;
height: 24px;
background-color: #333;
color: #fff;
display: flex;
align-items: center;
text-align: center;
justify-content: center;
border-radius: 100%;
font-size: 14px;
}
.QauntityManage {
float: left;
width: auto;
display: flex;
align-items: center;
column-gap: 4px;
background-color: #eee;
border-radius: 7px;
padding: 2px 5px;
}
.QauntityManage .btn {
padding: 4px 5px;
outline: none !important;
box-shadow: none !important;
border: 0px !important;
font-size: 15px;
}
.QauntityManage h2 {
font-size: 15px;
line-height: normal;
margin: 0px;
}
.QauntityManage .remove .bi {color: red;}
.QauntityManage .add .bi {color: #10b068;}
.GSTAmounttotal {
float: left;
width: 100%;
padding-left: 17px !important;
margin: 0px;
padding: 8px 0px;
border-top: 1px solid #ddd;
border-bottom: 1px solid #ddd;
margin-bottom: 10px;
}
.GSTAmounttotal li {
float: left;
width: 100%;
padding: 2px 0px;
font-size: 14px;
color: #333;
}
.GSTAmounttotal li label {
font-weight: 500;
float: left;
}
.FranchiseWallet {
float: left;
width: 100%;
display: flex;
align-items: center;
justify-content: space-between;
padding: 10px 0px;
border-bottom: 1px solid #ddd;
margin-bottom: 12px;
font-size: 15px;
}
.FranchiseWallet .Franchise_name {
padding: 2px 0px;
font-size: 14px;
color: #333;
display: flex;
column-gap: 10px;
}
.FranchiseWallet .Franchise_name label {font-weight: 500;}
.FranchiseWallet .FranchiseBlance_name {
font-size: 14px;
color: #333;
display: flex;
column-gap: 10px;
background-color: #f5f5f5;
padding: 6px 15px;
border-radius: 20px;
}
.FranchiseWallet .FranchiseBlance_name label {font-weight: 500;}
.GSTAmounttotal textarea.form-control {
border-radius: 0px;
box-shadow: none !important;
width: 300px;
float: left;
font-size: 13px;
padding: 8px 10px;
height: 95px;
}
.PurchaseOrderCheckbox .form-check {
  display: flex;
  align-items: center;
  column-gap: 8px;
}
.PurchaseOrderCheckbox .form-check-input[type=checkbox] {
height: 17px;
width: 17px;
}
.Purchase_Order_Mendetory .godown-select__placeholder::after {
  content: "*";
  color: red;
}
.pdffileviewo {
float: left;
width: 100%;
padding: 6px 23px 6px 14px;
font-size: 14px;
border: 1px solid #ddd;
background-color: #f9f9f9;
border-radius: 7px;
position: relative;
display: flex;
column-gap: 8px;
word-break: break-word;
}
.smallLabelData {
float: left;
width: 100%;
padding: 8px 10px;
border: 1px solid #ddd;
font-size: 13px;
position: relative;
height: 38px;
cursor: no-drop;
}
.smallLabelData small {
float: left;
width: auto;
background-color: #fff;
position: absolute;
left: 3px;
top: -8px;
padding: 0px 8px;
font-size: 11px !important;
}
.smallLabelData div {
display: inline-block;
width: 100%;
white-space: nowrap;
overflow: hidden !important;
text-overflow: ellipsis;
}
#backbtbackgt {
background-color: #0E0E30 !important;
}
.smallLabelData .clearbtn {
position: absolute;
right: -6px;
top: -7px;
line-height: normal;
background-color: #fff;
border-radius: 100%;
cursor: pointer;
}
.smallLabelData .clearbtn i {
color: red;
}
#FranchiseUserReport .userLimitselect {
  margin-right: 10px;
}
.notinternetFoundPage {
  float: left;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(255,255,255,0.5);
  backdrop-filter: blur(4px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}
.notinternetFoundPage .connectwrapp p {
  margin-bottom: 25px;
}
.notinternetFoundPage .connectwrapp {
  float: left;
  width: 540px;
  padding: 35px;
  background-color: #fff;
  border: 1px solid #ddd;
}

.notinternetFoundPage .connectwrapp h2 {
  font-weight: 600;
  margin: 0 0 12px;
}
.notinternetFoundPage .connectwrapp .btn {
  background-color: #10b068 !important;
  border-radius: 0px;
  border-color: #10b068 !important;
  padding: 9px 20px;
  font-size: 16px;
}
.notinternetFoundPage .connectwrapp .bi-wifi-off {
  font-size: 40px;
  color: red;
}
.formloginsetStaticData .form-label {
  margin: 0 0 5px !important;
}
.formloginsetStaticData .mb-3 {
  margin-bottom: 10px !important;
}
.qntyClass {
  border-radius: 0px;
  box-shadow: none;
  width: 110px !important;
}
.account-table{border-spacing: 2px;    border-collapse: collapse;print-color-adjust: exact;font-size: 13px;    color: #495057;margin: 0px!important;}
.account-table> tr>td, .account-table> tr>th {border: 1px solid #000000;border-right: 1px solid #000000;}
.account-table> tr:last-child>td, .account-table> tr:last-child>th{border-bottom: 1px solid #000000;}
.account-table> tr:first-child>td, .account-table> tr:first-child>th{border-top: 1px solid #000000;}
.account-table> tr>td, .account-table tr>td, .account-table tr>th{padding: 5px;}
.account-table h5{font-size: 14px;}
.account-table h2{font-size: 18px;}
.account-table hr{ margin: 0 -1px;padding: 0px!important;}
.account-table h5,
.account-table h2,
.account-table p{margin: 0px;padding: 0px!important;}
.account-table .bt-0{border-top-width: 0px!important;}
.account-table .bl-0{border-left-width: 0px!important;}
.account-table .bb-0{border-bottom-width: 0px!important;}
.account-table .br-0{border-right-width: 0px!important;}
.account-table .logo .text-center{width: calc(100% - 360px);}
.account-table .tax-invoice{
  background: #10b068;
    color: #fff;
    padding: 10px 26px;
    font-size: 20px;
    font-weight: bold;
    border-radius: 18px;
    display: inline-block;
}
.t-body-nowrap .carrier.min{min-width: 140px;}
.t-body-nowrap .carrier{white-space: nowrap;min-width: 170px;}
.table-common tr>th{font-size: 13px!important;}
.table-common tr>td{font-size: 13px!important;}
span.light-green{
  background-color: #eaf7ec !important;
  color: #2aa13d;
  font-weight: 500;
}
span.light-orange{
  background-color: #ffc107 !important;
  color: white;
  font-weight: 500;
}
span.light-red{
  background-color: #dc3545 !important;
  color: white;
  font-weight: 500;
}
span.light-grey{
  background-color: #acadb1 !important;
  color: white;
  font-weight: 500;
}
.btn.btn-icon{
  height: 35px;
  width: 35px;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  outline: 0px!important;
  border-color: transparent!important;
  box-shadow: none!important;
}
.btn.btn-icon::after{display: none;}
.btn.btn-icon i{font-size: 19px;}
.modal-invoice .btn.btn-icon{position: absolute;right: 50px;}
.invoice_model{
  -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-color-adjust: exact;
    color-adjust: exact;
}
.invoice-details-common img{object-fit: contain;}
.createshortBox {
  padding: 0px !important;
  font-size: 27px !important;
  line-height: normal !important;
  outline: none !important;
  border: 0px !important;
}
.addBoxbtns {
  background-color: #6c757d !important;
  border-color: #6c757d !important;
  color: #fff !important;
  padding: 6px 15px !important;
  font-size: 15px !important;
  border-radius: 0px !important;
}
.deleteItemBtn {
  color: red;
  font-size: 15px;
  cursor: pointer;
}
#addBoxbtns {
  margin: 0px;
  width: auto;
}
.selectBoxAnchor {
  float: right;
  font-size: 14px;
  text-decoration: underline;
  color: blue;
  cursor: pointer;
}
.createshortBox i {
  line-height: normal;
  color: #10b068;
}
@media print {
  .account-table{border-spacing: 2px;    border-collapse: collapse;print-color-adjust: exact;font-size: 13px;    color: #495057;margin: 0px!important;}
  .account-table tr>td, .account-table tr>th {border: 1px solid #000000!important;}
}

.commonLoaderwarap {
  float: left;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0,0.6);
  z-index: 99999;
  color: #fff;
  column-gap: 10px;
}
.commonLoaderwaraplogin {
  float: left;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0,0.6);
  z-index: 999;
  color: #fff;
  column-gap: 10px;
}
.serachBoxWIthNewMain .filedinfomsg {
  position: absolute;
  left: 10px;
  top: 11px;
}
.serachBoxWIthNewMain input {
  padding-left: 32px;
}
.replacebtn {
  color: #fff !important;
  padding: 6.4px 10px !important;
  margin: 0px !important;
}
.border-right {
  position: relative;
}
.border-right::after {
  content: "";
  float: left;
  width: 1px;
  height: 57%;
  background-color: #ccc;
  position: absolute;
  right: 0;
  top: 0;
}
.errorDetailsModal .modal-body p {
  float: left;
  width: 100%;
  word-break: break-word;
}
.customerdetails {
  float: left;
  width: 100%;
  font-size: 14px;
  margin: 0 0 10px;
}
#submitframebtn {
  width: auto;
  padding: 6.9px 10px !important;
  margin: 0px !important;
}
.customerdetails label {
  float: left;
  width: auto;
  margin-right: 7px;
  font-weight: 500;
}
ul.fileuploadListy {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
  margin-top: 10px;
}
ul.fileuploadListy li {
  float: left;
  width: 100%;
  list-style: none;
}
.fileuploadListy .uplaodedFilename {
  margin-bottom: 10px;
}
.fileuploadListytable {
  float: left;
  width: 100%;
  padding-left: 15px;
  margin: 0px;
}
.fileuploadListytable li {
  float: left;
  width: 100%;
  margin-bottom: 5px;
}
.UploadCmnBTN.UploadCmnAttchmentBTN .spinner-border {
  height: 20px;
  width: 20px;
  position: absolute;
  right: 10px;
  top: 6px;
}
.vertical {
  border-left: 2px solid #10b068;
  height: 50px;
  margin-left: 9px;
}
.completed{
  display: flex;
  align-items: center;
  background-color: #10b068;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  justify-content: center;
} 

.add-icon-francise-brand{
  margin-top: 29px;
}

.add-icon-francise-brand .AddItemBtn {
  color: white;
  font-size: 25px;
  cursor: pointer;
}

.permissionmodel .modal-dialog{
  max-width: 1200px;
}

.child {
  font-size: 14px;
}
.formloginset .ToggleMainWrap .permission-btn, .permissionbtn{
  background-color: #080606 !important;
  border-radius: 30px !important;
  padding: 7px 15px !important;
  font-size: 13px !important;
  color: #fff !important;
}
.Rm-Quick-Login-disable {
  cursor: no-drop;
}
.permissiontablAdminTolls #productMastersRMSMWH.form-check-input:disabled {
  background: gray;
  cursor: no-drop;
}
.usertyperPermission {
  width: auto;
  float: right;
  margin-left: 10px;
  text-transform: capitalize;
  font-size: 15px;
  padding: 8px 20px;
  line-height: normal;
  background-color: #46ff46;
  border-radius: 18px;
}
#margintopRemove {
  margin-top: 0px !important;
}

.iframe_wrapper {
  float: left;
  width: 100%;
  height: calc(100vh - 145px);
  position: relative;
  overflow: auto;
}
.iframe_wrapper iframe {
  margin: 0;
  padding: 0;
  display: block;
  overflow: hidden;
}
.errorMessageIcon {
  color: red;
}
.kvyregisterdnoregs {
  float: right;
  font-size: 12px;
  margin-left: 20px;
  border-left: 1px solid #dddd;
  padding-left: 12px;
}
.kvyregisterdnoregs .form-check.form-switch {
  margin: 0px;
  min-height: auto;
  display: flex;
  align-items: center;
  column-gap: 8px;
}
.kvyregisterdnoregs .form-check.form-switch input {height: 14px;}
.anchorDownoad {
  color: #0d6efd;
  text-decoration: underline;
  cursor: pointer;
}
.mobile-filters .filtr_Form .formloginset .col-md-3 {
  width: 100% !important;
  margin-bottom: 10px;
}
.mobile-filters-footer-grid {
  float: left;
  width: 100%;
  display: flex;
  align-items: center;
}
.mobile-filters-footer-grid .col-md-3 {
  width: auto !important;
}
#mobile-filters-footer-grid {
  float: left;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  column-gap: 15px;
}
#mobile-filters-footer-grid .col-md-3 {
  width: 50% !important;
}
#mobile-filters-footer-grid .col-md-3 select {width: 100% !important;}
.filter_btn {
  background-color: #f3f6fd !important;
  color: #0b5ade !important;
  border-radius: 0px !important;
  padding: 7px 10px !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  border: 1px solid #0b5ade !important;
}
.acccount_detailas {
  float: left;
  width: 100%;
  position: relative;
}
.acccount_detailas h4 {
  float: left;
  width: 100%;
  font-size: 16px;
  margin: 0 0 15px;
  position: relative;
}
.acccount_detailas h4 div {
  float: left;
  width: auto;
  background-color: #fff;
  padding-right: 10px;
  z-index: 9;
  position: relative;
}
.acccount_detailas h4::after {
  content: "";
  float: left;
  width: 100%;
  height: 1px;
  background-color: #ececec;
  position: absolute;
  right: 0;
  top: 11px;
}
.form_data {
  float: left;
  width: 100%;
  font-size: 14px;
  color: #6a6a6a;
}
.form_data_wrapper {
  float: left;
  width: 100%;
  margin: 0 0 15px;
  background-color: #f7f7f7;
  padding: 15px 20px;
}
.form_data_wrapper .form-label {
  float: left;
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: normal;
  margin-bottom: 5px;
}
.Main_section.mobile_reponsive_purchase_list .userLimitselect {
margin-right: 0px !important;
}
#loginMainblog .formloginset input {
  height: 50px;
  border: 0px;
  border-bottom: 2px solid #dee2e6;
  padding: 10px 10px 10px 41px;
  font-size: 14px !important;
}
.error_message_scanning {
  position: relative;
  z-index: 9999;
}
#loginMainblog .formloginset input:focus {
  border-color: #10b068 !important;
}
.login_input_icons .bi {
  color: #787878;
  font-size: 23px;
  position: absolute;
  left: 5px;
  top: 7px;
  z-index: 9;
}
.login_input_icons {
  float: left;
  width: 100%;
  position: relative;
}