@media only screen and (max-width: 991px) {
    .OrderDetailsModal .modal-dialog {max-width: 95%;}
    .OrderDetailsModal .modal-dialog {width: 95%;}
    #card-header-New-responsive {justify-content: end;}
    .sidebarWrapper, .adminmain .head_section .navLogo {width: 260px !important;}
    .panelwrapper {width: calc(100% - 260px);}
    #FranchiseUserReportWidthNew, #userLimitselect {display: none;}
    .ScrollbarsSidebar {height: calc(100ddvh - 182px) !important;}
    .searchBoxwithbtn {width: 220px;}
    .gstreporttableadded thead tr th, .gstreporttableadded tbody tr td {font-size: 12px !important;}
    .paginationCustom li button {width: 24px;height: 24px;min-width: 24px;font-size: 13px;}
    .datepickField input {background-position: 96% center;}
    .react-datepicker-popper {z-index: 99999 !important;}
    .card-header-New-responsive {float: left;width: 100%;margin-bottom: 9px;border-bottom: 1px solid #ededed;padding-bottom: 7px;display: flex;align-items: center;justify-content: space-between;}
    .card-header-New-responsive h4 {float: left;width: auto;text-transform: capitalize;font-size: 13px;margin-bottom: 0px;}
    .card-header-New-responsive .filter_btn {float: right;}
    .mobile-filters .mb-3 {margin-bottom: 5px !important;}
}
@media only screen and (max-width: 900px) {
    .tableView thead tr th, .tableView tbody tr td {white-space: nowrap;}
    .tableView.tableViewResponsive {height: calc(100ddvh - 166px);overflow: auto;}
    .tableView.tableViewFranchiseResponsive {height: calc(100ddvh - 150px);overflow: auto;}
}
@media only screen and (max-width: 700px) {
    #ScrollbarsSidebarStoreDetailsReport {
        height: calc(100dvh - 260px) !important;
    }
    .StockDetailsReportPage .MainHeader {
        overflow: hidden;
    }
    tbody, td, tfoot, th, thead, tr {
        vertical-align: middle;
    }
    #ScrollbarsSidebarMain {
        height: calc(100dvh - 167px) !important;
    }
    .ScrollbarsSidebar .view {margin-right: auto !important;padding-right: 0px;}
    .tableView.tableViewFranchiseResponsive {height: calc(100ddvh - 197px);}
    #card-header-New-responsive-Lodger-Report .card-header-New {width: auto;margin: 0px;}
    .paginationCustom {margin: 0px 0px 9px;}
    .form_data_wrapper {margin: 0 0 15px;padding: 12px 12px;}
    .form_data_wrapper .form-label, .form_data {font-size: 12px;}
    .acccount_detailas h4 {font-size: 13px;margin: 0 0 9px;}
    .acccount_detailas h4::after {top: 9px;}
    .acccount_detailas .ScrollbarsSidebarHide {height: calc(100dvh - 145px) !important;}
    .flex-column.responsiveSidebar.nav ul li a.active {background-color: #10b068;color: #fff !important;}
    .notifcations_icons i.bi.bi-bell {font-size: 19px;margin-right: 2px;line-height: normal;cursor: pointer;}
    .iframe_wrapper {height: calc(100dvh - 185px);}
    .MainHeader_Tab {column-gap: 7px;}
    .MainHeader {margin-bottom: 7px;padding-bottom: 0px;border-bottom: 0px;}
    .MainHeader_Tab li:hover::after, .MainHeader_Tab li.active::after {display: none;}
    .MainHeader_Tab li:hover, .MainHeader_Tab li.active {background-color: #10b068;color: #fff;}
    .MainHeader_Tab li {font-size: 13px;background-color: #f6f6f6;padding: 7px 10px;font-weight: 400;}
    .head_section {height: 43px;padding: 5px 7px;display: flex;align-items: center;text-align: center;float: none;justify-content: space-around;position: relative;border-bottom: 1px solid #ededed !important;}
    .head_section .responsive_profile {float: left;width: auto;}
    .head_section .responsive_profile img {float: none;width: 127px;margin: 0 auto;display: inline-table;}
    .respoMobileToggle {float: left;width: 40px;font-size: 30px;position: absolute;left: 0px;height: 100%;top: 0;background-color: #f9f9f9;display: flex;align-items: center;justify-content: center;}
    .respoMobileToggleClose {float: left;width: 40px;font-size: 23px;position: absolute;left: 0px;height: 100%;top: 0;background-color: #f9f9f9;display: flex;align-items: center;justify-content: center;}
    .dropdownHeader {position: absolute;right: 9px;top: 0;height: 100%;display: flex;align-items: center;justify-content: center;}
    .dropdownHeader .dropdown .dropdown-toggle {column-gap: 0px;}
    .panelwrapper {height: calc(100dvh - 103px);width: 100% !important;padding: 0px !important;}
    .responsive_header_title {float: left;width: 100%;text-transform: capitalize;font-size: 13px;margin-bottom: 9px;border-bottom: 1px solid #ededed;padding-bottom: 7px;}
    .ScrollbarsSidebar {height: calc(100dvh - 190px) !important;}
    .responsive_bottom_footer {float: left;width: 100%;position: fixed;bottom: 0;left: 0;background-color: #fff;border-top: 1px solid #ededed;padding: 5px 0px;margin: 0px;display: flex;align-items: center;justify-content: center;}
    .responsive_bottom_footer li {list-style: none;float: left;width: 30.33%;}
    .responsive_bottom_footer li a {float: left;width: 100%;display: flex;flex-direction: column;align-items: center;justify-content: center;color: #333;font-size: 12px;text-decoration: none;font-weight: 500;}
    .responsive_bottom_footer li a i {font-size: 20px;margin-bottom: 4px;line-height: normal;}
    .responsive_bottom_footer li a img {width: 20px;margin-bottom: 6px;}
    .responsive_bottom_footer li a.active i {color: #10b068;}
    .flex-column.responsiveSidebar.nav {float: left;width: 100%;position: fixed;left: 0;top: 43px;background-color: #fff;height: calc(100% - 102px);z-index: 9;}
    .flex-column.responsiveSidebar.nav ul {padding: 0px;margin: 11px 1px;}
    .flex-column.responsiveSidebar.nav ul li {float: left;width: 100%;list-style: none;border-bottom: 1px solid #eee;}
    .flex-column.responsiveSidebar.nav ul li a {float: left;width: 100%;padding: 13px 15px 13px 30px;text-decoration: none;color: #333;font-size: 14px;line-height: normal;position: relative;}
    .flex-column.responsiveSidebar.nav ul li a:after {content: "o";float: left;font-weight: 500;left: 14px;position: absolute;top: 11px;width: auto;}
    .MainHeader {overflow: auto;}
    .MainHeader_Tab {width: 500px;}
    .MainHeader .backbtn {position: absolute;top: 8px;right: 0;}
    .pending_for_store_section .OrderDetailsModal .orderDetailsViewr .order_details_logo {
        display: none;
    }
    .pending_for_store_section .OrderDetailsModal .orderDetailsViewr .order_details_head {
        text-align: left !important;
    }
    .HeaderDivider_product_design tr th {
        font-size: 13px !important;
        font-weight: 500;
    }
    .HeaderDivider_product_design tr td {
        font-size: 13px !important;
    }
    .logoheader img {
        width: 210px;
    }
}
@media only screen and (max-width: 540px) {
    .loginMainblog {
        padding: 25px 29px 35px;
    }
    #signInbtn {
        padding: 7px 30px;
    }
    .commonModal .modal-header .modal-title {font-size: 18px;}
    .commonModal .modal-header {padding: 12px 15px !important;}
    .HeaderDivider_product_design {padding: 30px 10px 10px 10px;}
    .error_message_scanning .alert-danger, .error_message_scanning .alert-primary {
        font-size: 14px;
        padding: 10px 15px;
    }
}
@media only screen and (max-width: 500px) {
    .framescanner_modal .frma_scanner_box #scanner .qrscanning_image {
        width: 90% !important;
        height: 85% !important;
        position: absolute;
        left: 0;
        top: 23px;
        z-index: 9;
        right: 0;
        margin: 0 auto;
    }
    .framescanner_modal .close_scanner_btn {
        padding: 0px;
    }
    .framescanner_modal .frma_scanner_box #scanner, .framescanner_modal .frma_scanner_box #scanner video {
        object-fit: cover;
        object-position: center;
    }
    .framescanner_modal .frma_scanner_box {width: 90% !important;}
    .Main_section.mobile_reponsive_purchase_list .userLimitselect {height: 30px !important;border-radius: 0px !important;font-size: 13px;width: 70px !important;padding: 3px 8px;}
    .searchBoxwithbtn .form-control {height: 30px;padding: 5px 35px 5px 25px;}

    .Main_section.StockDetailsReportPage .userLimitselect {height: 30px !important;border-radius: 0px !important;font-size: 13px;width: 70px !important;padding: 3px 8px;}

    .searchInfo {top: 8px;left: 7px;font-size: 10px;}
    .searchBoxwithbtn .icon-tabler-search {width: 15px;}
    .searchBoxwithbtn .searchbtn, .searchBoxwithbtn .searchbtn:focus {width: 28px;}
    .filter_btn {padding: 4.6px 8px !important;font-size: 13px !important;}
    #card-header-New-responsive-Lodger-Report .card-header-New select {height: 30px !important;width: 60px !important;padding: 4px 7px;}
    .tableView tbody tr td, .tableView tbody tr th {font-size: 12px;}
    .actionbtn {width: 13px;height: 13px;}
    .tablecollapsewraps table th {padding: 10px 5px;font-weight: 500;width: 115px !important;}
    .HeaderDivider_product_design tr th {
        font-size: 12px !important;
        font-weight: 500;
    }
    .HeaderDivider_product_design tr td {
        font-size: 12px !important;
    }
    .selectMultiselect .css-13cymwt-control, .selectMultiselect .css-1jqq78o-placeholder, .selectMultiselect .css-1dimb5e-singleValue {
        font-size: 12px !important;
    }
    .godown-select__indicator.godown-select__clear-indicator,
    .godown-select__indicator.godown-select__dropdown-indicator {
        padding: 2px !important;
    }
}
@media only screen and (max-width: 440px) {
    .loginMainblog{width: 90%;}
    .responsive_btn_grousp .btn {
        margin-bottom: 10px;
    }
}